import {Link as LinkScroll} from "react-scroll";
import styled from "styled-components";
import {Secondary} from "../../components/layouts/Secondary/Secondary";

const Wrapper = styled.section`
  padding: 100px 11.32%;
  @media (max-width: 599px) {
    padding: 50px 8.32%;
  }
  main {
    .footer-note {
      border-top: 1px solid #353945;
      font-size: 1.1rem;
      margin-top: 35px;
      padding-top: 10px;
    }
    .foot-note-number {
      font-size: 0.8rem;
    }
    ul {
      padding: 10px 21px;
      li {
        list-style: disc;
      }
    }
    li {
      list-style: auto;
      margin-top: 15px;
      font-size: 1.25rem;
      line-height: 34px;
      @media (max-width: 599px) {
        font-size: 16px;
        line-height: 27px;
      }
    }
    .list-style-none li {
      list-style: none;
    }
    .padding-none {
      padding: 0;
    }
    .margin-none {
      margin: 0;
    }
    .ul-li-margin-none {
      li {
        margin: 0;
      }
    }
    .line-height-normal {
      line-height: normal;
    }
    ol.list-style-lower-roman li {
      list-style: lower-roman;
    }
    ol {
      @media (max-width: 599px) {
        padding-left: 25px;
      }
    }
    h2 {
      margin-top: 30px;
      font-size: 1.6rem;
      text-align: left;
      @media (max-width: 599px) {
        font-size: 1.3rem;
        line-height: 1.6;
      }
    }
    p {
      margin-top: 10px;
      text-align: left;
    }
    .ol-left-padding {
      padding-left: 90px;
    }
    .underline-text {
      text-decoration: underline;
    }
    .link {
      color: #551a8b;
      cursor: pointer;
      font-size: 0.8rem;
      position: relative;
      right: 5px;
      top: -8px;
    }
  }
`;

const TermsAndConditions = () => (
  <Secondary>
    <Wrapper>
      <main>
        <h2 className="last-updated">Last updated: June 28, 2022</h2>
        <ol>
          <li>
            <h2>Purpose of this document</h2>
            <p>
              This document describes the arrangements put in place by Flahmingo
              Investments Inc. (“<strong>Flahmingo</strong>”, “we”, or “us”)
              intended to provide best execution and fair pricing for customers’
              orders.
            </p>
          </li>
          <li>
            <h2>Scope of the document</h2>
            <p>
              This document applies to Flahmingo customer orders for U.S.
              equities or fractions thereof that are not dually listed on a
              Canadian exchange (e.g. stocks and Exchange-traded Funds (ETFs))
              (collectively referred to as “<strong>Equities</strong>”).
            </p>
            <p>
              Flahmingo is a non-clearing firm and does not perform any type of
              clearing function for itself or others. Flahmingo does not hold
              customer funds or securities. We are an order execution only (OEO)
              venue that accepts and enters orders. For the purchase or sale of
              listed equities, Flahmingo directs customer orders for execution
              to our executing broker, Alpaca Securities LLC (“
              <strong>Alpaca</strong>”, “they”, or “their”). Customer
              transactions are sent through Alpaca to a third-party execution
              venue, which executes our orders. The third-party venue then sends
              the trade execution reports to Alpaca. Alpaca compares, allocates,
              clears, and settles our customers’ trades.
            </p>
          </li>
          <li>
            <h2>Best execution obligation</h2>
            <p>
              Best execution means obtaining the most advantageous execution
              terms for a customer order that are reasonably available. This is
              defined as National Best Bid and Offer (“<strong>NBBO</strong>”).
              NBBO is a regulation by the United States Securities and Exchange
              Commission (“<strong>SEC</strong>”) that requires brokers to
              execute customer trades at the best available ask price when
              buying securities, and the best available bid price when selling
              securities, as governed by Regulation National Market System (“
              <strong>NMS</strong>”).
            </p>
            <p>
              Flahmingo is a restricted dealer with our principal regulator
              being the Alberta Securities Commission. We are obligated to
              establish policies and procedures that are designed to achieve
              best execution when acting for a customer. These policies and
              procedures must take into consideration factors such as price,
              speed of execution, certainty of execution, and the overall cost
              of the transaction when costs are passed on to customers.
              Flahmingo monitors the best execution of its customers’ orders for
              the Equities listed on our platform.
            </p>
          </li>
          <li>
            <h2>Governance and oversight</h2>
            <p>
              The Ultimate Designated Person (UDP) has responsibility for
              applying Flahmingo’s Best Execution and Fair Pricing policies.
            </p>
            <p>
              Flahmingo undertakes to oversee our executing broker’s fulfillment
              of their best execution and fair pricing obligations on behalf of
              Flahmingo’s customers’ orders. This includes a review of their
              publicly available customer disclosure and order routing
              practices.
            </p>
            <p>
              Flahmingo will obtain annual attestations from our executing
              broker that they have complied with and tested their best
              execution policies and procedures. Flahmingo is also committed to
              reviewing the clearing services provided by the executing broker
              and will raise any perceived deficiencies identified in the course
              of its review.
            </p>
          </li>
          <li>
            <h2>Flahmingo customer order flow</h2>
            <p>
              Flahmingo has entered into an order routing and services agreement
              with Alpaca. As a member of Financial Industry Regulatory
              Authority (“<strong>FINRA</strong>”) and the Securities Investor
              Protection Corporation (“<strong>SIPC</strong>”), Alpaca is
              subject to similar best execution obligations. Alpaca provides
              Flahmingo order routing services for U.S. equities to market
              centres{" "}
              <LinkScroll
                className="link"
                to="foot-note"
                spy={true}
                smooth={true}
              >
                1
              </LinkScroll>{" "}
              with which Alpaca has order execution arrangements.
            </p>
            <p>
              Alpaca is responsible for the execution of Flahmingo’s customers’
              U.S. Equities. These orders are handled using Alpaca’s broker
              application programming interface (“<strong>Broker API</strong>”).
            </p>
          </li>
          <li>
            <h2>Inter-listed equities</h2>
            <p>
              The securities of certain reporting issuers listed on Canadian and
              U.S. marketplaces will not be made available to customers.
            </p>
          </li>
          <li>
            <h2>Best execution practices</h2>
            <p>
              Alpaca is required to seek best execution on their client orders
              per FINRA and SEC requirements. Further, Alpaca works with
              multiple market centers for end-to-end control over orders in an
              effort to provide the highest speed and quality of execution.
            </p>
            <p>
              Orders where the entire order is filled principally, from Alpaca’s
              inventory, Alpaca is providing the at or better than NBBO pricing.
              For orders routed to market makers, Alpaca sets expectations on
              how orders are to be handled based on the order size (bucket).
              Alpaca provides market makers their standards and then grades
              venues based on how they perform in each order bucket size against
              Alpaca’s established benchmark. Alpaca has three categories of
              benchmarks:
            </p>
            <ol>
              <li>
                <p>
                  Effective/quoted spread - Effective spread is the price a
                  customer paid compared to the midpoint of the NBBO multiplied
                  by two. The quoted spread is the difference between the NBBO
                  at time of order receipt. Effective spread over quoted spread
                  (EFQ) results in a percentage representing how much price
                  improvement an order received. 100% EFQ indicates an order was
                  executed at bid for a sell order or at ask for a buy order. A
                  0% EFQ indicates that the order received the midpoint between
                  the bid and offer.
                </p>
              </li>
              <li>
                <p>
                  Price improvement (“<strong>PI</strong>”) - Price improvement
                  (PI) occurs when your orders are executed at better prices
                  than NBBO. This benchmark is the average amount of net price
                  improvement per order, calculated as: Price improved dollars
                  minus amount executed outside the NBBO divided by number of
                  orders executed. This is also measured as a percentage of
                  shares executed at a price better than the NBBO.
                </p>
              </li>
              <li>
                <p>
                  Orders filled at or better NBBO - A measure of the percentage
                  of orders executed at or better than the National Best Bid or
                  Offer (NBBO). This measures how frequently your order is
                  filled at or better than the NBBO you see at the time you
                  entered it, even if your order is bigger than the displayed
                  size.
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p className="foot-note">
          <span className="foot-note-number">1</span> Market centre is an order
          fulfillment point. For the securities industry, the market centres are
          comprised of the floor of a stock exchange (via a specialist), market
          makers and electronic communication networks.
        </p>
      </main>
    </Wrapper>
  </Secondary>
);
export default TermsAndConditions;